<template>
  <div>
    <div class="button-container">
      <a
        v-if="hasDesc"
        class="help-btn"
        href="#"
        @click.prevent="openDescription"
      >
        <img
          :src="btn.help"
          alt="Ayuda"
        >
      </a>
      <a
        class="save-btn"
        href="#"
        @click.prevent="saveConfig"
      >
        <img
          :src="btn.save"
          alt="Guardar"
        >
      </a>
      <a
        class="save-btn"
        href="#"
        @click.prevent="downloadExercise"
      >
        <img
          :src="btn.download"
          alt="Descargar"
        >
      </a>
      <a
        v-if="audioStatus"
        href="#"
        @click.prevent="togglePlayPauseAudio"
      >
        <img
          v-if="audioStatus === 'stop' || audioStatus === 'pause'"
          :src="btn.play"
          alt="Reproducir audio"
        >
        <img
          v-if="audioStatus === 'play'"
          :src="btn.pause"
          alt="Pausar audio"
        >
      </a>
    </div>
    <div
      v-if="data"
      class="exercise-menu-options"
    >
      <a
        v-for="menu in data.menus"
        :key="menu.id"
        class="menu-btn"
        href="#"
        @click.prevent="toggleMenuNavigation(menu)"
        @mouseenter="setHoverMenu(menu.id)"
        @mouseleave="setHoverMenu(null)"
      >
        <template v-if="menu.image === ''">
          {{ menu.title }}
        </template>
        <template v-else>
          <img
            :src="getMenuImage(menu)"
            :alt="menu.title"
          >
        </template>
      </a>
    </div>
  </div>
</template>

<script>
import HelpBtn from "@/assets/img/btn/help-btn.png";
import SaveBtn from "@/assets/img/btn/save-btn.png";
import DownloadBtn from "@/assets/img/btn/download-btn.png";
import PlayBtn from "@/assets/img/controls/play.png";
import PauseBtn from "@/assets/img/controls/pause.png";
import ImageUtils from "@/utils/image";

export default {
  name: "ExerciseRightSidebar",
  props: {
    data: {
      type: Object,
      default: () => {
        return null
      }
    },
    currentMenu: {
      type: Object,
      default: () => {
        return null
      }
    },
    audioStatus: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      hoverMenu: null,
      btn:{
        save: SaveBtn,
        download: DownloadBtn,
        help: HelpBtn,
        play: PlayBtn,
        pause: PauseBtn
      },
    }
  },
  computed: {
    hasDesc(){
      return !!this.data?.description
    },
    hasAudio(){
      return !!this.data?.audio
    },
  },
  methods: {
    getImagePath(img) {
      return ImageUtils.getCDNFullPath(img)
    },
    getMenuImage(menu){
      const image = (this.currentMenu?.id === menu.id || this.hoverMenu === menu.id) && menu.image_alt?menu.image_alt:menu.image

      return this.getImagePath(image)
    },
    openDescription(){
      if(!this.data.description){
        return
      }

      this.$emit('open-description', this.data.description)
    },
    toggleMenuNavigation(menu){
      this.$emit('toggle-menu', menu)
    },
    togglePlayPauseAudio(){
      this.$emit('toggle-audio')
    },
    saveConfig(){
      this.$emit('save')
    },
    downloadExercise(){
      this.$emit('download')
    },
    setHoverMenu(menuId){
      this.hoverMenu = menuId
    }
  }
}
</script>

<style scoped lang="scss">
  .button-container{
    padding: 0.5rem 0.5rem 0 0.5rem;
  }
  .help-btn{
    display: block;
    text-align: center;

    img{
      max-width: 90%;
    }
  }

  .save-btn{
    display: block;
    text-align: center;

    &:last-child{
      margin-bottom: 0.5rem;
    }

    img{
      max-width: 90%;
    }
  }

  .menu-btn{
    display: block;
    font-size: 1rem;
    line-height: 1rem;
    padding:3px;
    text-align: center;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    margin-bottom: 0.25rem;

    img {
      max-width: 90%;
    }
  }
</style>
