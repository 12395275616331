<template>
  <div
    v-show="opened"
    class="menu-navigation"
    :style="{width, height}"
  >
    <div class="menu-title">
      <b-button
        v-if="closable"
        type="is-info"
        size="is-small"
        class="mr-3 is-uppercase"
        @click="close"
      >
        Cerrar
      </b-button>
      <span>{{ menuTitle }}</span>
    </div>
    <div class="menu-container">
      <div
        v-for="(item) in menuItems"
        :key="item.id"
        class="item"
      >
        <div
          class="inner-box"
          @click="selectItem(item)"
        >
          <img
            :class="getItemClasses(item)"
            :src="getImagePath(item.image)"
            :alt="item.title"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageUtils from "@/utils/image";

export default {
  name: "MenuNavigation",
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    menu: {
      type: Object,
      default: () => {
        return null
      }
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    closable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selecteds: {}
    }
  },
  computed: {
    menuItems() {
      if (!this.menu) {
        return []
      }

      return this.menu.items
    },
    menuTitle() {
      if (!this.menu) {
        return ''
      }

      return this.menu.title
    }
  },
  methods: {
    getItemClasses(item) {
      const itemBehaviour = item.behaviour
      if(item.close_on_select){
        return ''
      }
      if (this.selecteds.hasOwnProperty(itemBehaviour) && this.selecteds[itemBehaviour] === item.id) {
        return 'is-selected'
      }

      return ''
    },
    getImagePath(img) {
      return ImageUtils.getCDNFullPath(img)
    },
    selectItem(item) {
      const itemBehaviour = item.behaviour
      if (!this.selecteds.hasOwnProperty(itemBehaviour)) {
        this.$set(this.selecteds, itemBehaviour, item.id)
      } else {
        this.selecteds[itemBehaviour] = item.id
      }

      this.$emit('selected', item)
    },
    close(){
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.menu-navigation {
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 40px;
  background-color: white;
  z-index: 12;
  transition: width .5s ease-in-out;
  display: flex;
  flex-direction: column;

  .menu-title {
    padding: 0.5rem;
    background-color: lightgray;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .menu-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 20px;
  }

  .item {
    width: 16.666666667%;
    display: inline-block;
    cursor: pointer;

    .inner-box {
      padding: 10px;

      img {
        background-color: #C6D8E7;
        border-radius: 10px;
        padding: 3px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

        &.is-selected {
          border: 2px dashed black;
        }
      }
    }
  }
}
</style>
