<template>
  <div
    class="right-menu-container"
    :style="{right: rightPosition}"
  >
    <div class="switcher">
      <div
        v-if="open"
        v-touch:swipe.right="closeMenu"
        class="icon opened"
        @click.prevent="toggleMenu"
      >
        &gt;<br>&gt;<br>&gt;<br>&gt;
      </div>
      <div
        v-if="!open"
        v-touch:swipe.left="openMenu"
        class="icon closed"
        @click.prevent="toggleMenu"
      >
        &lt;<br>&lt;<br>&lt;<br>&lt;
      </div>
    </div>
    <div
      v-touch:swipe.right="closeMenu"
      class="right-side-menu"
      :style="{width, 'overflow-y': 'auto'}"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import LinkBtn from '@/assets/img/btn/link-btn.png'

export default {
  name: "RightSideMenu",
  props: {
    width: {
      type: String,
      default: '80px'
    },
    opened: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      options: {
        overlay: true,
        fullheight: true,
        right: true,
        fullwidth: false
      },
      open: true
    };
  },
  computed: {
    rightPosition() {
      if (!this.open) {
        return '-' + this.width
      }

      return 0
    }
  },
  watch: {
    opened(newVal){
      this.open = newVal
    }
  },
  mounted() {
    this.open = this.opened
  },
  methods: {
    selectedClass(routeName) {
      const currentRouteName = this.$router.currentRoute.name

      return routeName === currentRouteName
    },
    toggleMenu() {
      this.open = !this.open

      this.$emit('open', this.open)
    },
    closeMenu() {
      this.open = false

      this.$emit('open', this.open)
    },
    openMenu() {
      this.open = true

      this.$emit('open', this.open)
    }
  }
};
</script>

<style scoped lang="scss">
.right-menu-container {
  position: fixed;
  top: 40px;
  bottom: 0;
  right: 0;
  z-index: 13;
  display: flex;
  transition: right .5s ease-in-out;
  pointer-events: none;

  .switcher {
    margin-top: 30px;
    cursor: pointer;

    .icon {
      pointer-events: auto;
      font-weight: bold;
      display: block;
      background-color: #C6D8E7;
      height: auto;
      text-align: center;
      font-size: 0.5rem;
      border-radius: 15px 0 0 15px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

  }

  .right-side-menu {
    background-color: #C6D8E7;
    pointer-events: auto;
  }
}

</style>
