<template>
  <div
    v-if="itemId"
    class="no-v-scroll"
  >
    <media-viewer
      ref="exerciseViewer"
      :data="data"
      type="exercise"
      :scene-index="sceneIndex"
      @audio-status-changed="changedAudioStatus"
      @error="loadingError"
    />
    <text-modal
      v-model="textModal.content"
      :show="textModal.show"
      @close="textModalClose"
    />
    <menu-navigation
      ref="menuNavigation"
      :closable="true"
      :opened="menuNavigationOpened"
      :menu="selectedMenu"
      :width="containerWidth"
      :height="containerFullHeight"
      @selected="selectedMenuItem"
      @close="closeMenuNavigationModal"
    />
    <right-side-menu
      v-click-outside="closeRightSidebar"
      :opened="rightSideOpen"
      width="100px"
      @open="rightSideOpenChange"
    >
      <exercise-right-sidebar
        :audio-status="audioStatus"
        :data="data"
        :current-menu="selectedMenu"
        @toggle-audio="toggleAudioStatus"
        @toggle-menu="toggleMenu"
        @open-description="textModalOpen"
        @save="saveCurrentConfig"
        @download="downloadExercise"
      />
    </right-side-menu>
  </div>
</template>

<script>
import RightSideMenu from "@/components/RightSideMenu";
import ExerciseRightSidebar from "@/components/sidebars/ExerciseRightSidebar";
import MediaViewer from "@/components/medias/MediaViewer";
import TextModal from "@/components/modals/TextModal";
import MenuNavigation from "@/components/MenuNavigation";
import uuidv1 from 'uuid/dist/v1'
import ImageUtils from "@/utils/image";

export default {
  name: 'ExerciseDetail',
  components: {
    MenuNavigation,
    TextModal,
    MediaViewer,
    ExerciseRightSidebar,
    RightSideMenu
  },
  title: 'Ejercicio',
  beforeRouteLeave(to, from, next) {
    if (this.hasErrors) {
      next()
      return
    }

    if (this.ignoreRouteHooks) {
      next()
      return
    }

    this.$buefy.dialog.confirm({
      message: 'Estás a punto de abandonar la ficha, ¿Deseas continuar?',
      cancelText: 'No',
      confirmText: 'Si',
      onConfirm: () => {
        this.$refs.exerciseViewer.stopAll()
        this.$store.dispatch('orientationToChange', false)
        next()
      },
      onCancel: () => {
        next(false)
      }
    })
  },
  data: function () {
    return {
      itemId: null,
      data: null,
      hasErrors: false,
      sceneIndex: 0,
      section_title: 'Ejercicio',
      backgroundColor: 'transparent',
      containerWidth: '100%',
      containerFullHeight: '100%',
      containerHeight: '100%',
      textModal: {
        show: false,
        content: ''
      },
      rightSideOpen: false,
      menuNavigationOpened: false,
      audioStatus: null,
      selectedMenu: null,
      hasSavedCurrentExercise: false
    }
  },
  computed: {
    emptyResult() {
      return this.school === null
    },
    userToken() {
      return this.$store.state.token
    },
    hasAudio() {
      return this.data && !!this.data.audio
    },
    currentUserId() {
      return this.$store.state.user?.user_id
    }
  },
  watch: {
    userToken() {
      this.loadContent()
    },
    menuNavigationOpened(newVal) {
      if (!newVal) {
        this.$store.commit('setRouterInterceptorFnc', null)
      }

      if (newVal) {
        this.$store.commit('setRouterInterceptorFnc', this.closeMenuNavigationModal.bind(this))
      }
    },
    '$screen.landscape'() {
      this.checkOrientation()
    }
  },
  beforeMount() {
    this.itemId = this.$router.currentRoute.params.id

    if (this.$router.currentRoute.hash.indexOf('#') === 0) {
      this.sceneIndex = parseInt(this.$router.currentRoute.hash.replace('#', ''))

      if (Number.isNaN(this.sceneIndex)) {
        this.sceneIndex = 0
      }
    }

    this.checkOrientation()
  },
  async mounted() {
    if (this.itemId) {
      await this.loadContent()
    }
    await this.refreshItemSizes()
    this.$refs.exerciseViewer.setDrawConfig({color: 'transparent'})
    setTimeout(() => {
      this.restoreSavedItem(this.itemId)
    }, 800)

    window.addEventListener('resize', this.refreshItemSizes)
  },
  beforeDestroy() {
    this.$store.commit('setRouterInterceptorFnc', null)
  },
  destroyed() {
    window.removeEventListener('resize', this.refreshItemSizes)
  },
  methods: {
    getImagePath(img) {
      return ImageUtils.getCDNFullPath(img)
    },
    async loadContent() {
      try {
        await this.$store.dispatch('initLoading')
        const id = this.$route.params.id
        const bookId = this.$route.query?.book
        this.data = await this.$api.exercise.getById(id, bookId)
        if (this.data.audio) {
          this.audioStatus = 'stop'
        }
        this.setPageTitle(this.data.title)

        await this.$store.dispatch('finishLoading')
      } catch (error) {
        await this.$store.dispatch('finishLoading')

        await this.handleContentError(error)
      }
    },
    textModalOpen(description) {
      if (!description) {
        return
      }

      this.textModal.show = true
      this.textModal.content = description
    },
    textModalClose() {
      this.textModal.show = false
      this.textModal.content = ''
    },
    rightSideOpenChange(open) {
      this.rightSideOpen = open

      this.refreshItemSizes()
    },
    async refreshItemSizes() {
      await this.$nextTick()
      this.containerWidth = this.rightSideOpen ? (document.body.clientWidth - 80) + 'px' : '100%'
      //const fixedBarsHeight = 101
      this.containerFullHeight = document.body.clientHeight + 'px'
      //let itemHeight = this.$refs.container.clientHeight - fixedBarsHeight
      /*if (this.data?.sheets && this.data.sheets.length <= 1) {
        itemHeight += 60
      }
      itemHeight += 'px'
      this.containerHeight = itemHeight*/
    },
    closeRightSidebar() {
      if (this.menuNavigationOpened) {
        return
      }
      this.rightSideOpen = false
    },
    loadingError() {
      this.hasErrors = true
    },
    toggleAudioStatus() {
      this.$refs.exerciseViewer.toggleAudio()
    },
    changedAudioStatus(event) {
      this.audioStatus = event.status !== 'stop' ? event.status : 'pause'
    },
    toggleMenu(menu) {
      if (this.selectedMenu && this.selectedMenu.id === menu.id) {
        this.menuNavigationOpened = false
        this.selectedMenu = null
        return
      }

      this.selectedMenu = menu
      if(this.selectedMenu.items.length === 1){
        this.menuNavigationOpened = false

        this.selectedMenuItem(this.selectedMenu.items[0])
      }else{
        this.menuNavigationOpened = true
      }
    },
    closeMenuNavigationModal() {
      this.menuNavigationOpened = false
      this.selectedMenu = null
    },
    selectedMenuItem(item) {
      //console.info({...item})
      if (item.close_on_select === true) {
        this.menuNavigationOpened = false
        this.selectedMenu = null
      }

      if (item.behaviour === 'drawer_thickness') {
        this.$refs.exerciseViewer.setDrawConfig({thickness: parseInt(item.value), eraser: false, locked: false})
        return
      }

      if (item.behaviour === 'drawer_color') {
        const newConfig = {color: item.value, eraser: false, locked: false}
        this.$refs.exerciseViewer.setDrawConfig(newConfig)
        return
      }

      if (item.behaviour === 'drawer_eraser') {
        const newConfig = {eraser: true, locked: false}
        this.$refs.exerciseViewer.setDrawConfig(newConfig)
        return
      }

      if (item.behaviour === 'drawer_clear_canvas') {
        this.$refs.exerciseViewer.resetDraw()
        return
      }

      if (item.behaviour === 'drag_and_drop' || item.behaviour === 'text_box') {
        const viewer = this.$refs.exerciseViewer.getDimensions()

        const itemConfig = {
          id: uuidv1(),
          title: item.title,
          type: item.behaviour,
          value: item.value,
          image: this.getImagePath(item.image),
          aspectRatio: true,
          angle: 0,
          x: viewer.width / 2,
          y: viewer.height / 2,
          width: item.width,
          height: item.height,
          classes: []
        }
        //console.warn(itemConfig)
        //add item to drag/drop
        this.$refs.exerciseViewer.addDraggable(itemConfig)

        return
      }
    },
    checkOrientation() {
      if (!this.$screen.landscape) {
        this.$store.dispatch('orientationToChange', true)
      }

      if (this.$screen.landscape) {
        this.$store.dispatch('orientationToChange', false)
      }
    },
    async saveCurrentConfig() {
      try {
        if (!this.isAuth) {
          this.$msg.show('Inicia sesión con tu cuenta para poder guardar la ficha.')
          return
        }

        const exercise = {
          id: parseInt(this.itemId),
          user_id: this.currentUserId,
          points: this.$refs.exerciseViewer.getDrawPoints(),
          draggables: this.$refs.exerciseViewer.getDraggableItems()
        }

        if (!this.hasSavedCurrentExercise) {
          await this.$db.add(process.env.VUE_APP_EXERCISE_STORE, exercise)
        } else {
          await this.$db.update(process.env.VUE_APP_EXERCISE_STORE, exercise)
        }
        this.hasSavedCurrentExercise = true
        this.$msg.success(`<b>${this.data.title}</b> se ha guardado correctamente.`)

        await this.$store.dispatch('loadExercises')
      } catch (e) {
        console.error('-- error al guardar --')
        console.error(e)
        this.$msg.error('No se ha podido guardar la ficha, inténtalo más tarde')
      }
    },
    restoreSavedItem(itemId) {
      const exercises = this.$store.state.exercises

      const exerciseIndex = exercises.findIndex((item) => item.id === parseInt(itemId))
      this.hasSavedCurrentExercise = exerciseIndex >= 0
      if (exerciseIndex >= 0) {
        this.$buefy.dialog.confirm({
          message: 'Tienes guardada esta ficha, ¿Deseas recuperarla?',
          cancelText: 'No',
          confirmText: 'Si',
          onConfirm: async () => {
            this.loadSavedItem(exercises[exerciseIndex])
          },
          onCancel: () => {
            this.$refs.exerciseViewer.setDrawConfig({enabled: true})
          }
        })
      } else {
        this.$refs.exerciseViewer.setDrawConfig({enabled: true})
      }
    },
    loadSavedItem(savedConfig) {
      this.$refs.exerciseViewer.setDrawConfig({enabled: false})
      this.$refs.exerciseViewer.setDrawConfig({initialImage: savedConfig.points})
      this.$refs.exerciseViewer.addDraggables(savedConfig.draggables)
      this.$refs.exerciseViewer.setDrawConfig({enabled: true})
    },
    downloadExercise() {
      if (!this.isAuth) {
        this.$msg.show('Solo los usuarios registrados pueden descargar las fichas.')
        return
      }

      this.$refs.exerciseViewer.saveToImg()
    }
  }
}
</script>

<style scoped lang="scss">
.no-v-scroll {
  overflow-y: hidden;
}

.audio-player {
  z-index: 11;
  bottom: 60px !important;
  transition: width 0.5s ease-in-out;
}

.image-container {
  transition: 0.5s ease-in-out;
  display: flex;
  justify-content: center;

  img {
    width: auto;
    height: auto;
    object-fit: contain;
  }
}
</style>
